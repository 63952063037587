<template>
    <v-app>
        <NavBar />
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
export default {
    name: 'App',
    components: {
        NavBar,
    },
    data() {
        return {
            sessionTimeout: 30 * 60 * 1000, // 30 minutes in milliseconds
            sessionTimer: null,
        };
    },
    methods: {
        resetSessionTimer() {
            if (this.sessionTimer) {
                clearTimeout(this.sessionTimer);
            }

            this.sessionTimer = setTimeout(() => {
                this.logout();
            }, this.sessionTimeout);
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('uuid');
            this.$root.$emit('loginStatusUpdated');
            this.$router.push('/login');
        },
        onActivity() {
            this.resetSessionTimer();
        },
    },
    mounted() {
        const token = localStorage.getItem('token');
        this.isLoggedIn = !!token;

        // Set up session timeout
        this.resetSessionTimer();

        // Listen for user activity
        window.addEventListener('mousemove', this.onActivity);
        window.addEventListener('keydown', this.onActivity);
        window.addEventListener('scroll', this.onActivity);
    },
    beforeDestroy() {
        // Clean up event listeners
        window.removeEventListener('mousemove', this.onActivity);
        window.removeEventListener('keydown', this.onActivity);
        window.removeEventListener('scroll', this.onActivity);
    },
};
</script>

<style>
.router-style {
    text-decoration: none;
    color: white;
}
</style>
