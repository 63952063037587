<!-- NavBar.vue -->
<template>
    <v-app-bar app color="primary" dark>
        <div class="d-flex align-center">
            <router-link to="/dashboard" class="router-style ml-2" style="color: white">
                <h1>IncreaSEO</h1>
            </router-link>
        </div>
        <v-spacer></v-spacer>
        <router-link
            :to=" '/login'"
            class="router-style ml-2"
            style="color: white"
            @click="toggleLoginState"
        >
            {{ isLoggedIn ? 'Logout' : 'Login' }}
        </router-link>
    </v-app-bar>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            isLoggedIn: false,
        };
    },
    methods: {
        toggleLoginState() {
            if (this.isLoggedIn) {
                this.logout();
            }
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('uuid');
            this.$root.$emit('loginStatusUpdated');
            this.$router.push('/login');
        },
        checkLoginStatus() {
            const token = localStorage.getItem('token');
            this.isLoggedIn = !!token;
        },
    },
    created() {
        this.checkLoginStatus();
    },
};
</script>

<style>
.router-style {
    text-decoration: none;
    color: white;
}
</style>
