import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token');
//   const isLoggedIn = !!token;
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // If the route requires authentication
//     if (!isLoggedIn) {
//       // If not logged in, redirect to the login page
//       next('/login');
//     } else {
//       // If logged in, proceed to the route
//       next();
//     }
//   } else {
//     // If the route doesn't require authentication, proceed to the route
//     next();
//   }
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
